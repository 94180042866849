import React from "react";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import App from "./App";
import { Provider } from "react-redux";
import rootStore from "../store/rootStore";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./ErrorFallback";
import { CheckSsoLoginChange, authConfig } from "../store/authToken";
import { LOCALSTORAGEKEYS } from "./constants/constant";

interface Config extends Configuration {
  auth: any;
  cache: any;
  apiURL: string;
  apimKey: string;
  imageURL: string;
  imageToken: string;
  scope: string[];
}

export const MSAAPP = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const b2b = urlParams.get(LOCALSTORAGEKEYS.B2B);

  CheckSsoLoginChange(b2b);

  const instance = new PublicClientApplication(authConfig());

  return process.env.REACT_APP_CLIENT_ID_B2C ? (
    <React.StrictMode>
      <Provider store={rootStore}>
        <MsalProvider instance={instance}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error, errorInfo) => console.log({ error, errorInfo })}
          >
            <App apiURL="" apimKey="" scope={[]} {...authConfig()} />
          </ErrorBoundary>
        </MsalProvider>
      </Provider>
    </React.StrictMode>
  ) : (
    <div>
      <h1>
        Unable to read the env file. Please add environments to the project!{" "}
      </h1>
    </div>
  );
};