import { Container } from "@mui/material";
import { useUrlParams } from "../../../shared/utils/hooks";
import { useHeaderWidget } from "../BatteryMonitoring/components/HeaderWidget/useHeaderWidget";
import { HeaderWidget } from "../BatteryMonitoring/components/HeaderWidget";
import { SiteInfoWidget } from "../BatteryMonitoring/components/SiteInfoWidget";
import { Grid } from "@material-ui/core";
import { BatteryControlTable } from "./components/BatteryControlTable";
import { ChartWrapper } from "../BatteryMonitoring/components/ChartWrapper";
import { BatteryControlChartWidget } from "../BatteryMonitoring/components/BatteryControlChartWidget";

export const BatteryDetails: React.FC = () => {
  const { startDate, endDate } = useUrlParams({ startDate: "", endDate: "" });

  const {
    portfolio,
    plant,
    selectedPortfolio,
    selectedPlant,
    setDate,
    globalDate,
    onChangePortfolio,
    onChangePlant,
    liveButton,
    handleChangeLiveButton,
  } = useHeaderWidget();

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        padding: "16px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderWidget
        selectedPortfolio={selectedPortfolio}
        portfolio={portfolio}
        selectedPlant={selectedPlant}
        plant={plant}
        date={globalDate}
        setDate={setDate}
        onChangePortfolio={onChangePortfolio}
        onChangePlant={onChangePlant}
        liveButton={liveButton}
        handleChangeLiveButton={handleChangeLiveButton}
      />

      <Grid container spacing={2} style={{ marginTop: "12px" }}>
        <Grid item xs={12}>
          <ChartWrapper>
            <BatteryControlChartWidget detailed height={550} />
          </ChartWrapper>
        </Grid>
        <Grid item xs={12}>
          <BatteryControlTable />
        </Grid>
      </Grid>
    </Container>
  );
};
