import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Highcharts from "highcharts";
import Highstock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import { ChartTopMenu } from "./ChartTopMenu";

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}

NoDataToDisplay(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/map")(Highcharts);

if (typeof Highstock === "object") {
  HighchartsExporting(Highstock);
}

NoDataToDisplay(Highstock);
require("highcharts/modules/exporting")(Highstock);
require("highcharts/modules/export-data")(Highstock);
require("highcharts/modules/map")(Highstock);

interface Props {
  dynamicSeries?: boolean;
  detailed?: boolean;
  menu?: {
    dropdown?: {
      value: number;
      items: {
        label: string;
        value: number;
      }[];
      onChange: (value: number | string) => void;
    };
    buttonGroups?: {
      buttons: {
        label: string;
        onClick: () => void;
        active?: boolean;
      }[];
    }[];
  };
  chartOptions: any;
}

export const CustomCombinedChart = (props: Props) => {
  const [menuHeight, setMenuHeight] = useState(0);
  const [isMenuOverlaping, setIsMenuOverlaping] = useState(false);

  const {
    chartOptions,
    menu: { dropdown, buttonGroups },
  } = props;

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const chartContainer = useMemo(() => {
    if (!chartRef.current?.chart) {
      return null;
    }

    const element = chartRef.current?.chart.container;

    return element;
  }, [chartRef.current?.chart]);

  const _chartOptions = useMemo(() => {
    return {
      ...chartOptions,
      series: props?.dynamicSeries ? [] : chartOptions.series,
      title: {
        ...chartOptions.title,
        margin: isMenuOverlaping
          ? chartOptions.title.margin + menuHeight + 8
          : chartOptions.title.margin,
      },
      exporting: {
        ...chartOptions.exporting,
        buttons: {
          contextButton: {
            y: 16,
          },
        },
      },
    };
  }, [chartOptions, isMenuOverlaping, menuHeight]);


  // there's a custom solution for adding series to the chart.
  // highcharts by default has an issue with adding series to the chart after it was rendered (it's not updating the chart)
  // so let's say that we have 2 series and then we have changed date range for requests inside the chart and another additional 3 series are loaded
  // in this case, new series haven't been visible at the chart
  // it was working after page refresh
  // so there comes the solution for adding series to the chart after it was rendered
  // by default, we are "overriding" series by empty array (to avoid duplicating series)
  // and then we iterate over latest series and just adding it into the chart.
  // it's working fine, but looks ugly
  useEffect(() => {
    const series = chartOptions?.series;

    if (!props?.dynamicSeries || !series) {
      return;
    }

    chartRef.current?.chart?.series?.forEach((serie) => {
      serie?.remove ? serie.remove() : undefined;
    });

    series?.forEach((serie) => {
      chartRef.current?.chart?.addSeries(serie);
    });

    return () => {
      chartRef.current?.chart?.series?.forEach((serie) => {
        serie?.remove ? serie.remove() : undefined;
      });
    }
  }, [chartRef.current?.chart, chartOptions, props?.dynamicSeries]);

  const handleResize = useCallback((height: number, isOverlaping: boolean) => {
    setMenuHeight(height);
    setIsMenuOverlaping(isOverlaping);
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <HighchartsReact
        highcharts={props?.detailed ? Highstock : Highcharts}
        options={_chartOptions}
        style={{ height: "auto", display: "block", width: "100%" }}
        ref={chartRef}
        constructorType={props?.detailed ? "stockChart" : "chart"}
      />
      {chartContainer && (
        <ChartTopMenu
          container={chartContainer}
          dropdown={dropdown}
          buttonGroups={buttonGroups}
          onResize={handleResize}
        />
      )}
    </div>
  );
};
